import App from './App.vue'
import ElementPlus from 'element-plus'
import axios from "axios"
import { createApp } from 'vue'

const app=createApp(App)

app.config.globalProperties.$axios = axios
app.use(ElementPlus)
app.mount('#app')
